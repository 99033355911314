import { NumberDirective } from "./shared/directives/numbers-only.directive";
import { NgModule } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";
import { CurrencyPipe, DatePipe, PercentPipe } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./public/header/header.component";
import { HomeComponent } from "./public/home/home.component";
import { PhoneMaskDirective } from "./shared/directives/phone-mask.diretive";
import { FaqComponent } from "./public/faq/faq.component";
import { EssApiService } from "./shared/services/essapi.service";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CorrelationInterceptor } from "./shared/interceptors/correlation-interceptor";
import { DollarAmountPipe } from "./shared/pipes/dollarAmount.pipe";
import { SortDirective } from "./shared/directives/sort.directive";
import { AccountNumberPipe } from "./shared/pipes/accountNumber.pipe";
import { SearchFilterPipe } from "./shared/pipes/search-filter";
import { SafePipe } from "./shared/pipes/safe.pipe";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { SpaOverridesComponent } from './public/spa-overrides/spa-overrides.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule } from "@angular/material/paginator";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTreeModule } from '@angular/material/tree';
import { NavBarComponent } from "./public/nav-bar/nav-bar.component";
import { LoadingSpinnerComponent } from "./public/loading-spinner/loading-spinner.component";
import { LoadingSpinnerInterceptor } from "./shared/interceptors/loading-spinner-interceptor";
import { ServicePointEditComponent } from './public/service-point-edit/service-point-edit.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { EntityManagementComponent } from './public/entities/entity-management.component';
import { EntitiesComponent } from './public/entities/entities/entities.component';
import { EntityTypesComponent } from './public/entities/entity-types/entity-types.component';
import { EntityTypeGroupsComponent } from './public/entities/entity-type-groups/entity-type-groups.component';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import { OneuiframeworkModule } from "@/oneuiframework";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthCheckInterceptor } from "./shared/interceptors/auth-check-interceptor";
import { ScalarUsageComponent } from "./public/scalar-usage/scalar-usage.component";
import { IntervalUsageComponent } from "./public/interval-usage/interval-usage.component";
import { ServicePointListComponent } from "./public/service-point-list/service-point-list.component";
import { ServicePointReferenceDateComponent } from "./public/service-point-reference-date/service-point-reference-date.component";
import { ServicePointDetailsComponent } from "./public/service-point-details/service-point-details.component";
import { ServicePointSummaryComponent } from "./public/service-point-summary/service-point-summary.component";
import { ProfileSegmentComponent } from "./public/profile-segment/profile-segment.component";
import { ScalarProfilingComponent } from "./public/scalar-profiling/scalar-profiling.component";
import { BaseUrlInterceptor } from "./shared/interceptors/base-url-interceptor";
import { PlanningYearComponent } from "./public/planning-year/planning-year.component";
import { CoincidentPeakComponent } from "./public/coincident-peak/coincident-peak.component";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HomeComponent,
        PhoneMaskDirective,
        FaqComponent,
        DollarAmountPipe,
        SortDirective,
        NumberDirective,
        AccountNumberPipe,
        SearchFilterPipe,
        SafePipe,
        AccountNumberPipe,
        SpaOverridesComponent,
        NavBarComponent,
        LoadingSpinnerComponent,
        ServicePointEditComponent,
        ServicePointListComponent,
        ServicePointReferenceDateComponent,
        ServicePointDetailsComponent,
        ServicePointSummaryComponent,
        EntityManagementComponent,
        EntitiesComponent,
        EntityTypesComponent,
        EntityTypeGroupsComponent,
        ScalarUsageComponent,
        IntervalUsageComponent,
        ProfileSegmentComponent,
        ScalarProfilingComponent,
        PlanningYearComponent,
        CoincidentPeakComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        OneuiframeworkModule,
        FormsModule,
        ReactiveFormsModule,
        AngularEditorModule,
        BrowserAnimationsModule,
        MatPaginatorModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatTabsModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatTooltipModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatDialogModule,
        AppRoutingModule,
        MatSelectModule,
        MatCheckboxModule,
        DragDropModule,
        MatRadioModule,
        MatSliderModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatTreeModule,
        ScrollingModule,
        MatFormFieldModule,
        AgGridAngular
    ],
    exports: [PhoneMaskDirective, AccountNumberPipe],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CorrelationInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingSpinnerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthCheckInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
        DatePipe,
        CurrencyPipe,
        PercentPipe,
        AccountNumberPipe,
        EssApiService,
        provideHttpClient(withInterceptorsFromDi())
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
